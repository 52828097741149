/* eslint-disable array-callback-return */
import Logo from "../../../components/Logo/Logo";
import Modal2 from "../../../components/Modal/Modal2";
import { NSCandidateScoreResultStyle } from "./Candidate-Score-Result.style";

import useCandidateScoreResultVm from "./Candidate-Score-ResultVM";
import Loading from "../../../components/Loading/Loading";
import ScoreResultSidebar from "./ScoreResultSidebar/ScoreResultSidebar";
import i18n from "../../../library/i18next";
import TabbedView from "../../../components/TabbedView/TabbedView";
import { Outlet } from "react-router-dom";
import SolidButton from "../../../components/SolidButton/SolidButton";
import LinkButton from "../../../components/LinkButton/LinkButton";
import colors from "../../../style/colors";
import ScoreResultDrawer from "./ScoreResultDrawer/ScoreResultDrawer";
import { ButtonColorVariant } from "../../../components/MainButton/MainButton.type";
import { NSCandidateListType } from "../../Project/Detail/Candidate-List/Candidate-List.type";

/* const HTMLContentRenderer = ({ content }: { content: string }) => {
  // Function to convert newlines to <br> tags and wrap paragraphs
  const formatContent = (text: string) => {
    // Replace \n with actual line breaks in HTML
    const withLineBreaks = text.replace(/\\n/g, "<br />");

    // Split by double line breaks to create paragraphs
    const paragraphs = withLineBreaks.split("<br /><br />");

    // Join paragraphs with proper HTML tags
    return paragraphs.map((p) => `<p>${p}</p>`).join("");
  };

  return (
    <div
      className="prose max-w-none text-gray-700 leading-relaxed"
      dangerouslySetInnerHTML={{
        __html: formatContent(content),
      }}
    />
  );
}; */

function CandidateScoreResult() {
  const candidateScoreResultVM = useCandidateScoreResultVm();

  return candidateScoreResultVM.isLoading ? (
    <Loading />
  ) : (
    <Modal2 onClose={candidateScoreResultVM.closeHandler} isFullWidth>
      <NSCandidateScoreResultStyle.Container id="content">
        <NSCandidateScoreResultStyle.Header>
          <Logo type="onlyIcon" />
          {candidateScoreResultVM.baseType ===
          NSCandidateListType.ScorePageBaseType.INTERVIEW
            ? i18n.t("candidate.englishTestReport")
            : i18n.t("candidate.competencyInterviewReport")}
        </NSCandidateScoreResultStyle.Header>
        <NSCandidateScoreResultStyle.Content id="sub-content">
          <NSCandidateScoreResultStyle.LeftContent>
            <ScoreResultSidebar
              data={candidateScoreResultVM.reportSidebarData}
            />
          </NSCandidateScoreResultStyle.LeftContent>
          <NSCandidateScoreResultStyle.RightContent>
            <NSCandidateScoreResultStyle.Navbar>
              <TabbedView
                onTabChange={(tab) => candidateScoreResultVM.onTabChange(tab)}
                tabItems={candidateScoreResultVM.tabItems}
                activeTab={candidateScoreResultVM.activeTab}
              />
              <NSCandidateScoreResultStyle.NavbarButtonsContainer
                isCompared={candidateScoreResultVM.secondInterviewId}
              >
                <SolidButton
                  backgroundColor={
                    candidateScoreResultVM.secondInterviewId
                      ? ButtonColorVariant.error
                      : ButtonColorVariant.primary
                  }
                  label={
                    candidateScoreResultVM.secondInterviewId
                      ? i18n.t("candidate.cancelCompare")
                      : i18n.t("candidate.compareCandidate")
                  }
                  variant="label-w-icon-prefix"
                  prefixIcon={
                    candidateScoreResultVM.secondInterviewId
                      ? "Close"
                      : "ArrowLeftRight"
                  }
                  onClick={candidateScoreResultVM.handleCompareClick}
                />
                <LinkButton
                  label={i18n.t("candidate.downloadPDF")}
                  variant="label-w-icon-prefix"
                  prefixIcon="Download"
                  iconColor={colors.secondary.secondary}
                  onClick={candidateScoreResultVM.downloadButton}
                />
                {/*   <LinkButton
                  label={i18n.t("candidate.share")}
                  variant="label-w-icon-prefix"
                  iconColor={colors.secondary.secondary}
                  prefixIcon="Share"
                  onClick={() => {}}
                /> */}
              </NSCandidateScoreResultStyle.NavbarButtonsContainer>
            </NSCandidateScoreResultStyle.Navbar>

            <Outlet />
          </NSCandidateScoreResultStyle.RightContent>
        </NSCandidateScoreResultStyle.Content>
      </NSCandidateScoreResultStyle.Container>
      {candidateScoreResultVM.isDrawerVisible && (
        <ScoreResultDrawer
          onClickCloseIcon={candidateScoreResultVM.closeDrawer}
        />
      )}
    </Modal2>
  );
}

export default CandidateScoreResult;
