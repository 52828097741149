import i18n from "../../../../../library/i18next";
import { NSScoreDistributionStyle } from "./ScoreDistribution.style";
import { NSScoreDistributionType } from "./ScoreDistribution.type";

const ScoreDistribution = ({
  items,
}: NSScoreDistributionType.IScoreDistribution) => {
  return (
    <NSScoreDistributionStyle.Container>
      <NSScoreDistributionStyle.Header>
        {i18n.t("candidate.scoreDistribution")}
      </NSScoreDistributionStyle.Header>
      <NSScoreDistributionStyle.List>
        {items.map((i) => (
          <NSScoreDistributionStyle.ListItem key={i.reviewMetric}>
            <NSScoreDistributionStyle.ReviewMetric>
              {i.reviewMetric}
            </NSScoreDistributionStyle.ReviewMetric>
            <NSScoreDistributionStyle.Score>
              {i.scores.map((score) => (
                <NSScoreDistributionStyle.ScoreContainer
                  key={i.reviewMetric}
                  isBackgroundActive={i.scores.length > 1}
                >
                  <NSScoreDistributionStyle.Bold>
                    {score}
                  </NSScoreDistributionStyle.Bold>
                  /100
                </NSScoreDistributionStyle.ScoreContainer>
              ))}
            </NSScoreDistributionStyle.Score>
          </NSScoreDistributionStyle.ListItem>
        ))}
      </NSScoreDistributionStyle.List>
    </NSScoreDistributionStyle.Container>
  );
};

export default ScoreDistribution;
