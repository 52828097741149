import { Trans } from "react-i18next";
import ScoreChart from "../../../../../components/ScoreChart/ScoreChart";
import { NSGeneralScoreStyle } from "./GeneralScore.style";
import { NSGeneralScoreType } from "./GeneralScore.type";
import i18n from "../../../../../library/i18next";

const GeneralScore = ({ data }: NSGeneralScoreType.IGeneralScoreData) => {
  return (
    <NSGeneralScoreStyle.Container>
      <NSGeneralScoreStyle.Header>
        {i18n.t("projects.overallScore")}
      </NSGeneralScoreStyle.Header>
      <NSGeneralScoreStyle.Content>
        {data.length > 0 &&
          data.map((i) => (
            <NSGeneralScoreStyle.ScoreContainer key={i.id}>
              {i.candidate}
              <ScoreChart
                progress={i?.score}
                stringProgressValue={i.stringProgressValue}
              />
              {i.order !== 0 && (
                <NSGeneralScoreStyle.Text>
                  <Trans
                    i18nKey={
                      data.length > 1
                        ? "candidate.generalScoreOrderText2"
                        : "candidate.generalScoreOrderText"
                    }
                    values={{
                      order: i.order,
                    }}
                    components={{
                      bold: <NSGeneralScoreStyle.Bold />,
                    }}
                  />
                </NSGeneralScoreStyle.Text>
              )}
            </NSGeneralScoreStyle.ScoreContainer>
          ))}
      </NSGeneralScoreStyle.Content>
    </NSGeneralScoreStyle.Container>
  );
};

export default GeneralScore;
