import styled from "styled-components";
import { NSOutlinedButtonStyle } from "../../../../components/OutlinedButton/OutlinedButton.style";
import { NSMainButtonStyle } from "../../../../components/MainButton/MainButton.style";

export namespace NSCScoreResultSidebarStyle {
  export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    padding: 24px;
    border-radius: 16px;
    background-color: ${(props) => props.theme.colors.light.light};
    height: max-content;
    margin-bottom: 16px;
    min-width: 234px;
  `;

  export const FullName = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};

    margin-bottom: 8px;
  `;

  export const PersonalInfo = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    margin-bottom: 8px;
  `;

  export const Strong = styled.span`
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
  `;

  export const ViewCvContainer = styled.div`
    padding: 8px 0 16px;
  `;

  export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: ${(props) => props.theme.colors.neutrals.grey03};
    margin: 16px 0;
  `;

  export const SidebarButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 16px 0 24px;

    ${NSOutlinedButtonStyle.OutlinedButtonBase} {
      ${NSMainButtonStyle.ButtonBase} {
        border-width: 2px;
        :hover {
          svg {
            path {
              stroke: ${(props) => props.theme.colors.light.light};
            }
          }
        }
      }
    }
  `;

  export const BadgeContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
  `;
}
