import { NSScoreResultInfoBadgeStyle } from "./ScoreResultInfoBadge.style";
import { NSScoreResultInfoBadgeType } from "./ScoreResultInfoBadge.type";

const ScoreResultInfoBadge = ({
  variant,
  title,
  label,
}: NSScoreResultInfoBadgeType.IScoreResultInfoBadge) => {
  return (
    <NSScoreResultInfoBadgeStyle.InfoBadgeContainer variant={variant}>
      <NSScoreResultInfoBadgeStyle.InfoBadgeTitle>
        {title}
      </NSScoreResultInfoBadgeStyle.InfoBadgeTitle>
      <NSScoreResultInfoBadgeStyle.InfoBadgeLabel>
        {label}
      </NSScoreResultInfoBadgeStyle.InfoBadgeLabel>
    </NSScoreResultInfoBadgeStyle.InfoBadgeContainer>
  );
};

export default ScoreResultInfoBadge;
