import { getBadgeColor } from "../../../../../utils/getBadgeColorHelper";
import { NSHeatmapStyle } from "../Heatmap.style";
import { NSHeatmapType } from "../Heatmap.type";

const HeatmapBadge = ({
  score,
  withPercentage,
}: NSHeatmapType.IHeatmapBadge) => {
  return (
    <NSHeatmapStyle.Badge
      color={getBadgeColor(score).color}
      backgroundColor={getBadgeColor(score).backgroundColor}
    >
      {withPercentage && "%"}
      {score}
    </NSHeatmapStyle.Badge>
  );
};

export default HeatmapBadge;
