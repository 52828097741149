/* c8 ignore start */
const colors = {
  primary: {
    primary: "hsla(234, 70%, 50%, 1)",
    dark: "hsla(234, 70%, 29%, 1)",
    light: "hsla(235, 70%, 94%, 1)",
    tint: "hsla(237, 60%, 94%, 1)",
    tint02: "hsla(233, 70%, 67%, 1)",
    tint03: "hsla(234, 69%, 77%, 1)",
    tint05: "hsl(234, 70%, 57%)",
  },

  secondary: {
    secondary: "hsla(240, 75%, 15%, 1)",
    dark: "hsla(240, 24%, 36%, 1)",
    light: "hsla(240, 14%, 89%, 1)",
  },

  tertiary: {
    tertiary: "hsla(133, 64%, 64%, 1)",
    dark: "hsla(133, 37%, 37%, 1)",
    light: "hsla(135, 67%, 95%, 1)",
  },

  dark: {
    dark: "hsla(0, 0%, 0%, 1)",
  },

  light: {
    light: "hsla(0, 0%, 100%, 1)",
  },

  neutrals: {
    grey01: "hsla(210, 20%, 98%, 1)",
    grey02: "hsla(216, 24%, 96%, 1)",
    grey03: "hsla(220, 14%, 88%, 1)",
    grey04: "hsla(217, 12%, 78%, 1)",
    grey05: "hsla(218, 15%, 65%, 1)",
    grey06: "hsla(221, 13%, 46%, 1)",
    grey07: "hsla(216, 18%, 34%, 1)",
    grey08: "hsla(218, 24%, 27%, 1)",
    grey09: "hsla(214, 33%, 17%, 1)",
  },

  orange: {
    orange: "hsl(32, 100%, 34%)",
    orange02: "hsl(33, 100%, 91%)",
  },

  shadesOfdark: {
    dark01: "hsla(0, 0%, 0%, 0.85)",
    dark02: "hsla(0, 0%, 0%, 0.65)",
    dark03: "hsla(0, 0%, 0%, 0.45)",
    dark04: "hsla(0, 0%, 0%, 0.25)",
    dark05: "hsla(0, 0%, 0%, 0.10)",
    dark06: "hsla(0, 0%, 0%, 0.05)",
  },

  shadesOflight: {
    light85: "hsla(0, 0%, 100%, 0.85)",
    light65: "hsla(0, 0%, 100%, 0.65)",
    light45: "hsla(0, 0%, 100%, 0.45)",
    light25: "hsla(0, 0%, 100%, 0.25)",
    light10: "hsla(0, 0%, 100%, 0.1)",
    light05: "hsla(0, 0%, 100%, 0.05)",
  },

  error: {
    red01: "hsla(349, 73%, 56%, 1)",
    red02: "hsla(357, 71%, 95%, 1)",
    red03: "hsla(356, 60%, 36%, 1)",
  },

  warning: {
    yellow01: "hsla(39, 85%, 59%, 1)",
    yellow02: "hsla(44, 100%, 95%, 1)",
    yellow03: "hsla(39, 90%, 40%, 1)",
  },

  success: {
    green01: "hsla(137, 93%, 38%, 1)",
    green02: "hsla(136, 100%, 96%, 1)",
    green03: "hsla(137, 86%, 17%, 1)",
  },

  information: {
    blue01: "hsla(209, 100%, 46%, 1)",
    blue02: "hsla(209, 100%, 96%, 1)",
    blue03: "hsla(209, 100%, 24%, 1)",
  },

  gradients: {
    gradientsCars: {
      secondary: "hsla(234, 70%, 50%, 0)",
      primary: "hsla(240, 75%, 15%, 1)",
    },
    gradientsCirclePrimary: {
      primary: "hsla(234, 70%, 50%, 1)",
      grey02: "hsla(210, 6%, 94%, 0)",
    },
    gradientsCircleTeriary: {
      tertiary: "hsla(133, 64%, 64%, 1)",
      grey02: "hsla(210, 6%, 94%, 0)",
    },
  },

  infoBadgeGreen: {
    background: "#D3F5E1",
    color: "#1F572B",
  },

  infoBadgeBlue: {
    background: "#D3F2F5",
    color: "#154347",
  },

  infoBadgeBrown: {
    background: "#FFE3C4",
    color: "#553715",
  },
};

export default colors;
/* c8 ignore stop */
