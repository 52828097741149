import styled from "styled-components";
import { CardHeader } from "ui";

export namespace NSScoreResultOverviewStyle {
  export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
  `;

  export const TopContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 16px;
    width: 100%;
  `;

  export const TopContentLeftSide = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    max-width: 100%;
  `;

  export const TopContentRightSide = styled.div`
    background-color: ${(props) => props.theme.colors.light.light};
    border-radius: 16px;
    padding: 16px;

    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
  `;

  export const BottomContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  `;

  export const BottomContentLeftSide = styled.div`
    background-color: ${(props) => props.theme.colors.light.light};
    border-radius: 16px;
    padding: 16px;

    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
  `;
  export const BottomContentRightSide = styled.div`
    background-color: ${(props) => props.theme.colors.light.light};
    border-radius: 16px;
    padding: 16px;

    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    overflow: hidden;
    box-sizing: border-box;
  `;

  export const CardHeaderDiv = styled(CardHeader)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    padding: 0;
  `;

  export const CardHeaderInfo = styled.div``;

  export const CardHeaderTitle = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;
  export const CardHeaderSubtitle = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.smallText2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;
}
