import React from "react";
import { NSScoreBadgeType } from "./ScoreBadge.type";
import { NSScoreBadgeStyle } from "./ScoreBadge.style";
import i18n from "../../library/i18next";
import { getBadgeColor } from "../../utils/getBadgeColorHelper";

const ScoreBadge: React.FC<NSScoreBadgeType.IScoreBadge> = ({
  description,
  score,
}) => {
  return (
    <NSScoreBadgeStyle.Container>
      <NSScoreBadgeStyle.Description>
        {description}
      </NSScoreBadgeStyle.Description>
      <NSScoreBadgeStyle.Badge
        color={getBadgeColor(score).color}
        backgroundColor={getBadgeColor(score).backgroundColor}
      >
        {score} {i18n.t("interview.point")}
      </NSScoreBadgeStyle.Badge>
    </NSScoreBadgeStyle.Container>
  );
};

export default ScoreBadge;
