import styled from "styled-components";
import { NSDropdownStyle } from "../Dropdown/Dropdown.style";
import { NSMainButtonStyle } from "../MainButton/MainButton.style";
import { NSMainInputStyle } from "../MainInput/MainInput.style";

export namespace NSDashboardHeaderStyle {
  export const Container = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: ${(props) => props.theme.colors.light.light};
    height: 96px;
    width: 100%;
    padding: 0 24px;
    border-radius: 16px;
  `;

  export const LeftItems = styled.div`
    ${NSDropdownStyle.InnerContainer} {
      ${NSDropdownStyle.Placeholder} {
        white-space: wrap;
        width: auto;
        overflow: auto;
      }
    }
  `;

  export const RightItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;

    ${NSMainButtonStyle.ButtonBase} {
      height: 48px;
    }

    ${NSDropdownStyle.DropdownItemsContainer} {
      width: 220px;
    }
  `;

  export const DropdownButtonContainer = styled.div`
    min-width: 204px;
  `;

  export const DropdownContainer = styled.div`
    min-width: 180px;
    ${NSDropdownStyle.DropdownTitle} {
      height: 48px;

      ${NSDropdownStyle.InnerDropdownTitle} {
        height: 48px;
        padding: 0 12px;
      }
    }
  `;

  export const InputContainer = styled.div`
    width: 180px;
    ${NSMainInputStyle.InputFlex} {
      height: 48px;
    }
  `;
}
