import styled from "styled-components";
import { NSDropdownStyle } from "../../../components/Dropdown/Dropdown.style";
import { NSMainButtonStyle } from "../../../components/MainButton/MainButton.style";
import { NSBreadcrumbStyle } from "../../../components/Breadcrumb/Breadcrumb.style";
import { NSBatchActionStyle } from "../../../components/BatchAction/BatchAction.style";

export namespace NSProjectDetailStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;

    ${NSDropdownStyle.AddNewItemButtonContainer} {
      ${NSMainButtonStyle.ButtonBase} {
        font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
      }
    }

    ${NSDropdownStyle.Placeholder} {
      white-space: nowrap;
      width: 90px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    ${NSBatchActionStyle.DropdownContainer} {
      ${NSDropdownStyle.Placeholder} {
        white-space: wrap;
        width: auto;
        overflow: auto;
      }
    }

    ${NSBreadcrumbStyle.Container} {
      ${NSDropdownStyle.InnerDropdownTitle} {
        width: 100%;
      }

      ${NSDropdownStyle.Placeholder} {
        width: auto;
        max-width: 260px;
      }
    }
  `;

  export const PageContent = styled.div<{ fullHeight?: boolean }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 140px);
    background-color: ${(props) => props.theme.colors.light.light};
    padding: 24px 32px;
    border-radius: 16px;
    overflow: hidden;

    ${(props) =>
      props.fullHeight &&
      `
      height: auto;
      min-height: 100%;
    `}
  `;
}
