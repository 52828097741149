import { useLocation, useNavigate, useParams } from "react-router-dom";
import Icon from "../../../../components/Icon/Icon";
import { NSTableListType } from "../../../../components/TableList/TableList.type";
import {
  GetSignedUrlDocument,
  GetVideoInterviewScoresByProjectIdDocument,
  Order_By,
  SearchDocument,
  Video_Interview_Status_Enum,
  Video_Interview_Type_Enum,
} from "../../../../gql/graphql";
import {
  useGraphQLMutation,
  useGraphQLQuery,
} from "../../../../hooks/useGraphQL";
import HeatmapBadge from "./Badge/HeatmapBadge";
import { NSHeatmapStyle } from "./Heatmap.style";
import i18n from "../../../../library/i18next";
import { useCallback, useState } from "react";
import CustomTooltip from "../../../../components/Tooltip/Tooltip";
import fileTypeHelper from "../../../../utils/fileTypeHelper";
import {
  CV,
  DEFAULT_PAGINATION_OPTIONS,
  GET,
  PAGINATION_DEFAULT_LIMIT,
} from "../../../../utils/globalConstants";
import toast from "react-hot-toast";
import ToastAlert from "../../../../components/ToastAlert/ToastAlert";
import candidateStatusHelper from "../../../../utils/candidateStatusHelper";
import dayjs from "dayjs";
import { excelExporter } from "../../../../utils/excelExporter";
import { getLevel } from "../../../../utils/getLevelHelper";

const interviewTypeOptions = [
  {
    id: 1,
    label: i18n.t("projects.competencyInterview"),
  },
  {
    id: 2,
    label: i18n.t("projects.englishTest"),
  },
];

const orderOptions = [
  {
    id: 1,
    label: i18n.t("projects.scoreAsc"),
  },
  {
    id: 2,
    label: i18n.t("projects.scoreDesc"),
  },
];

const useHeatmapVM = () => {
  const { projectId } = useParams();
  const [selectedInterviewType, setSelectedInterviewType] = useState<number>(1);
  const [selectedOrder, setSelectedOrder] = useState<number>(1);
  const [searchString, setSearchString] = useState<string>("");
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const start = searchParams.get("start");
  const limit = searchParams.get("limit");
  const startValue = parseInt(start!) || 0;
  const limitValue = parseInt(limit!) || PAGINATION_DEFAULT_LIMIT;
  const isValidLimit = DEFAULT_PAGINATION_OPTIONS.some(
    (option) => parseInt(option.label) === limitValue
  );
  const [pagination, setPagination] = useState<{
    start: number;
    limit: number;
  }>({
    start: startValue,
    limit: isValidLimit ? limitValue : PAGINATION_DEFAULT_LIMIT,
  });
  const navigate = useNavigate();

  const { mutateAsync: getSignedUrl } =
    useGraphQLMutation(GetSignedUrlDocument);

  const { data: searchData } = useGraphQLQuery(
    SearchDocument,
    {
      enabled: !!searchString,
    },
    {
      index: "client_candidate",
      query: searchString ?? "",
      limit: pagination.limit,
      offset: pagination.start,
    }
  );

  const clientCandidateIds =
    searchData?.search?.clientCandidate?.map((item) => item?.id) || [];

  const listFilter = {
    video_interview_type: {
      _eq:
        selectedInterviewType === 1
          ? Video_Interview_Type_Enum.CompetencyAssessment
          : Video_Interview_Type_Enum.EnglishAssessment,
    },
    interview_last_status: { _eq: Video_Interview_Status_Enum.Scored },
    client_candidate_project: {
      _and: [
        { project_id: { _eq: projectId } },
        searchString
          ? {
              client_candidate_id: { _in: clientCandidateIds },
            }
          : {},
      ],
    },
  };

  const { data: scoresData, isLoading } = useGraphQLQuery(
    GetVideoInterviewScoresByProjectIdDocument,
    {},
    {
      projectId,
      limit: pagination.limit,
      offset: pagination.start,
      orderBy: selectedOrder === 1 ? Order_By.Desc : Order_By.Asc,
      listFilter: listFilter,
    }
  );

  const data =
    scoresData?.video_interview
      ?.filter((interview) => interview.score !== null)
      .map((interview, index) => {
        const candidateName = `${interview.client_candidate_project.client_candidate.name} ${interview.client_candidate_project.client_candidate.surname}`;

        const reviewMetrics =
          selectedInterviewType === 1
            ? interview.video_interview_questions
                .flatMap((question) => {
                  const validMetrics =
                    question.review_metric_question.review_metric.review_metric_questions.filter(
                      (rmq) =>
                        rmq.interview_template_review_metric_questions.some(
                          (itrmq) =>
                            itrmq.weight > 0 &&
                            itrmq.interview_template_id ===
                              interview.interview_template_id
                        )
                    );

                  return validMetrics.map((validMetric) => {
                    const matchedResponse =
                      question.review_metric_question.review_metric_question_responses.find(
                        (response) =>
                          question.video_interview_score.some(
                            (score) =>
                              score.review_metric_question_response_id ===
                              response.id
                          )
                      );

                    const description =
                      question.review_metric_question.review_metric.description;
                    const score = matchedResponse
                      ? matchedResponse.score
                      : null;

                    return description && score !== null && score !== 0
                      ? {
                          description,
                          score,
                          metricId: validMetric.id,
                        }
                      : null;
                  });
                })
                .filter((metric) => metric !== null)
            : interview.video_interview_criterion_scores.map((i) => ({
                description: i.interview_criterion.name,
                score: i.score,
              }));

        return {
          id: index,
          interviewId: interview.id,
          candidate: candidateName,
          score: interview.score,
          reviewMetrics: reviewMetrics,
          clientCandidateId:
            interview.client_candidate_project.client_candidate.id,
        };
      }) || [];

  const handleDownload = useCallback(async (url: string, name: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const linkkk = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = linkkk;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(linkkk);
    } catch (error) {
      console.log("Download failed:", error);
    }
  }, []);

  const handleDownloadCVButton = async (id: string) => {
    let fileUrlData: any;

    const fileName = scoresData?.video_interview.find(
      (interview) =>
        interview.client_candidate_project.client_candidate.id === id
    )?.client_candidate_project.client_candidate.cv_file_name;

    if (fileName) {
      const type = fileTypeHelper(fileName);
      fileUrlData = await getSignedUrl({
        signedUrlInput: {
          contentType: type.type!,
          filenames: [fileName],
          folder: CV,
          operation: GET,
        },
      });

      handleDownload(
        fileUrlData?.getSignedUrl?.signedUrls[0] as string,
        fileName
      );
    } else {
      toast(
        <ToastAlert description={i18n.t("projects.noCvError")} type="error" />,
        {
          id: "cvDownloadError",
        }
      );
    }
  };

  const allReviewMetrics = data
    .flatMap((item) => item.reviewMetrics || [])
    .filter(
      (metric, index, self) =>
        self.findIndex((m) => m?.description === metric?.description) === index
    );

  const handleDownloadListButton = async () => {
    const response = scoresData!;

    const allCandidatesInformationDataForExcel = response?.video_interview.map(
      (interview) => {
        const candidate = interview.client_candidate_project.client_candidate;

        const competencyAssessment =
          interview.video_interview_type ===
          Video_Interview_Type_Enum.CompetencyAssessment
            ? interview
            : null;

        const englishTest =
          interview.video_interview_type ===
          Video_Interview_Type_Enum.EnglishAssessment
            ? interview
            : null;

        const lastUpdateDate =
          interview.client_candidate_project.video_interviews[0]
            ?.video_interview_statuses[0]?.created_at ?? null;

        const reviewMetricsData = allReviewMetrics.map((metric) => {
          const matchedMetric =
            selectedInterviewType === 1
              ? interview.video_interview_questions
                  .flatMap((question) => {
                    const validMetrics =
                      question.review_metric_question.review_metric.review_metric_questions.filter(
                        (rmq) =>
                          rmq.interview_template_review_metric_questions.some(
                            (itrmq) =>
                              itrmq.weight > 0 &&
                              itrmq.interview_template_id ===
                                interview.interview_template_id
                          )
                      );

                    return validMetrics.map(() => {
                      const matchedResponse =
                        question.review_metric_question.review_metric_question_responses.find(
                          (response) =>
                            question.video_interview_score.some(
                              (score) =>
                                score.review_metric_question_response_id ===
                                response.id
                            )
                        );

                      const description =
                        question.review_metric_question.review_metric
                          .description;
                      const score = matchedResponse
                        ? matchedResponse.score
                        : null;

                      return description &&
                        score !== null &&
                        score !== 0 &&
                        description === metric?.description
                        ? { description, score }
                        : null;
                    });
                  })
                  .find((m) => m !== null)
              : interview.video_interview_criterion_scores.find(
                  (i) => i.interview_criterion.name === metric?.description
                );

          return {
            description: metric?.description,
            score: matchedMetric
              ? selectedInterviewType === 1
                ? matchedMetric.score
                : matchedMetric.score
              : 0,
          };
        });

        return {
          name: candidate.name,
          surname: candidate.surname,
          email: candidate.email_address,
          mobileNumber: candidate.mobile_number,
          competencyLastStatus:
            competencyAssessment?.interview_last_status ?? null,
          competencyLastScore: competencyAssessment?.score ?? 0,
          englishTestLastStatus:
            englishTest?.interview_last_status ??
            Video_Interview_Status_Enum.NotSet,
          englishTestLastScore: getLevel(englishTest!?.score) ?? 0,
          candidateStatus:
            interview.client_candidate_project.candidate_resolution,
          score: interview.score ?? 0,
          lastUpdateDate,
          reviewMetricsData,
        };
      }
    );

    const dataForExcel = allCandidatesInformationDataForExcel
      ?.filter((item) => item.competencyLastScore || item.englishTestLastScore)
      .map((item) => {
        const competencyInterviewStatus =
          item.competencyLastStatus === Video_Interview_Status_Enum.Scored
            ? item.competencyLastScore
            : candidateStatusHelper(
                String(item.competencyLastStatus),
                String(item.competencyLastScore)
              ).label;

        const englishTestStatus =
          item.englishTestLastStatus === Video_Interview_Status_Enum.Scored
            ? item.englishTestLastScore
            : candidateStatusHelper(
                String(item.englishTestLastStatus),
                String(item.englishTestLastScore)
              ).label;

        let lastUpdateDate: string | null = null;

        if (item.lastUpdateDate) {
          const parsedDate = dayjs(item.lastUpdateDate);
          if (parsedDate.isValid()) {
            lastUpdateDate = parsedDate.format("DD.MM.YYYY[,] hh:mm");
          }
        }

        const baseData = {
          [i18n.t("projects.candidateName")]: `${item.name} ${item.surname}`,
          [i18n.t("projects.emailAddress")]: item.email,
          [i18n.t("projects.phoneNumber")]: item.mobileNumber,
          ...(item.competencyLastScore
            ? {
                [i18n.t("projects.competencyInterviewStatus")]:
                  competencyInterviewStatus,
              }
            : {}),
          ...(item.englishTestLastScore
            ? { [i18n.t("projects.englishTestStatus")]: englishTestStatus }
            : {}),
          [i18n.t("projects.lastUpdateDate")]: lastUpdateDate,
        };

        const reviewMetricsColumns: Record<string, string> = {};
        allReviewMetrics.forEach((metric) => {
          const matchedMetric = item.reviewMetricsData.find(
            (m) => m.description === metric?.description
          );
          reviewMetricsColumns[metric?.description!] = matchedMetric
            ? `%${matchedMetric.score}`
            : "0%";
        });

        return {
          ...baseData,
          ...reviewMetricsColumns,
        };
      });

    if (dataForExcel && dataForExcel.length > 0) {
      const projectName = scoresData?.video_interview
        .map(
          (interview) => interview.client_candidate_project.project.project_name
        )
        .filter(Boolean)[0];

      const date = dayjs().format("DD.MM.YYYY[,] HH:mm");

      excelExporter(dataForExcel, `hc-${projectName}-export-${date}.xlsx`);
    } else {
      console.log("No valid data available for download.");
    }
  };

  const headerItemList = [
    i18n.t("projects.order"),
    i18n.t("projects.candidateName"),
    i18n.t("projects.scoreText"),
    "",
    ...allReviewMetrics.map((metric, index) => (
      <NSHeatmapStyle.IconContainer
        key={index}
        data-tooltip-id={`metric-tooltip-${index}`}
        data-tooltip-content={metric?.description}
        data-tooltip-place="top"
      >
        {metric?.description}
      </NSHeatmapStyle.IconContainer>
    )),
    <NSHeatmapStyle.DownloadListButtonContainer key="download-button">
      <NSHeatmapStyle.DownloadListButton
        size="small"
        label={i18n.t("candidate.downloadList")}
        onClick={handleDownloadListButton}
      />
    </NSHeatmapStyle.DownloadListButtonContainer>,
  ];

  const heatmapTableData: NSTableListType.ITableListItem[] =
    data.length > 0
      ? data.map((item, index) => {
          return {
            id: item.id.toString(),
            informations: [
              {
                id: "1",
                information: (index + 1).toString(),
              },
              {
                id: "2",
                information: item.candidate,
              },
              {
                id: "3",
                information: <HeatmapBadge score={item.score ?? 0} />,
              },
              {
                id: "4",
                information: (
                  <NSHeatmapStyle.StyledIcons>
                    <span
                      data-tooltip-id={`icon-tooltip-download-${item.id}`}
                      data-tooltip-content={i18n.t("projects.downloadCv")}
                      onClick={() => {
                        handleDownloadCVButton(item.clientCandidateId);
                      }}
                    >
                      <Icon name="Download" />
                      <CustomTooltip id={`icon-tooltip-download-${item.id}`}>
                        {i18n.t("projects.downloadCv")}
                      </CustomTooltip>
                    </span>
                    <span
                      data-tooltip-id={`icon-tooltip-star-${item.id}`}
                      data-tooltip-content={i18n.t("projects.candidateReport")}
                      onClick={() => {
                        navigate(
                          `/projects/detail/${projectId}/candidate/candidate-score-result/${
                            item.interviewId
                          }/${
                            selectedInterviewType === 1
                              ? "question"
                              : "interview"
                          }/overview`,
                          {
                            state: {
                              projectId,
                              interviewId: item.interviewId,
                              hasCameFromHeatmap: true,
                            },
                          }
                        );
                      }}
                    >
                      <Icon name="NoteSearch" />
                      <CustomTooltip id={`icon-tooltip-star-${item.id}`}>
                        {i18n.t("projects.candidateReport")}
                      </CustomTooltip>
                    </span>
                  </NSHeatmapStyle.StyledIcons>
                ),
              },
              ...item.reviewMetrics.map((metric, metricIndex) => ({
                id: (metricIndex + 5).toString(),
                information: (
                  <HeatmapBadge withPercentage score={metric?.score ?? 0} />
                ),
              })),
            ],
          };
        })
      : [];

  const handleSetPagination = useCallback((start: number, limit: number) => {
    setPagination({ start, limit });

    navigate(
      `/projects/detail/${projectId}/heatmap?start=${start}&limit=${limit}`,
      {
        state: { projectId },
      }
    );
  }, []);

  return {
    heatmapTableData,
    headerItemList,
    interviewTypeOptions,
    selectedInterviewType,
    searchString,
    isLoading,
    totalItemCount:
      scoresData?.client_candidate_aggregate.aggregate?.count ?? 0,
    pagination,
    orderOptions,
    selectedOrder,
    setSelectedOrder,
    setSearchString,
    setSelectedInterviewType,
    handleSetPagination,
  };
};

export default useHeatmapVM;
