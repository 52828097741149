import DropdownWithCaption from "../../../../components/DropdownWithCaption/DropdownWithCaption";
import Loading from "../../../../components/Loading/Loading";
import Pagination from "../../../../components/Pagination/Pagination";
import SearchInput from "../../../../components/SearchInput/SearchInput";
import TableList from "../../../../components/TableList/TableList";
import i18n from "../../../../library/i18next";
import { PAGINATION_DEFAULT_LIMIT } from "../../../../utils/globalConstants";
import { NSHeatmapStyle } from "./Heatmap.style";
import useHeatmapVM from "./HeatmapVM";

const Heatmap = () => {
  const {
    heatmapTableData,
    headerItemList,
    interviewTypeOptions,
    selectedInterviewType,
    isLoading,
    searchString,
    pagination,
    totalItemCount,
    orderOptions,
    selectedOrder,
    setSelectedOrder,
    handleSetPagination,
    setSearchString,
    setSelectedInterviewType,
  } = useHeatmapVM();

  return (
    <NSHeatmapStyle.TableListContainer>
      <NSHeatmapStyle.InputsContainer>
        <SearchInput
          placeholder={i18n.t("projects.searchCandidate")}
          id={"search"}
          name={"search"}
          onChange={(value: string) => {
            setSearchString(value);
          }}
          value={searchString}
        />
        <DropdownWithCaption
          prefixTitle={i18n.t("projects.interviewType")}
          id={1}
          options={interviewTypeOptions}
          type={"basic"}
          defaultCheckedItems={[
            {
              id: selectedInterviewType,
              label: interviewTypeOptions.find(
                (x) => x.id === selectedInterviewType
              )?.label,
            },
          ]}
          selectedDropdownItem={{
            id: selectedInterviewType,
            label: interviewTypeOptions.find(
              (x) => x.id === selectedInterviewType
            )?.label,
          }}
          onSelect={(_id, selected) => {
            setSelectedInterviewType((selected?.id as number) ?? 1);
          }}
        />
        <DropdownWithCaption
          prefixTitle={i18n.t("projects.sortBy")}
          id={1}
          options={orderOptions}
          type={"basic"}
          defaultCheckedItems={[
            {
              id: selectedOrder,
              label: orderOptions.find((x) => x.id === selectedOrder)?.label,
            },
          ]}
          selectedDropdownItem={{
            id: selectedOrder,
            label: orderOptions.find((x) => x.id === selectedOrder)?.label,
          }}
          onSelect={(_id, selected) => {
            setSelectedOrder((selected?.id as number) ?? 1);
          }}
        />
      </NSHeatmapStyle.InputsContainer>
      {isLoading ? (
        <Loading />
      ) : heatmapTableData && heatmapTableData.length > 0 ? (
        <>
          <TableList
            tableItemList={heatmapTableData}
            headerItemList={headerItemList}
          />
          {totalItemCount >= PAGINATION_DEFAULT_LIMIT && (
            <NSHeatmapStyle.PaginationContainer>
              <Pagination
                pagination={pagination}
                isLoading={isLoading}
                itemCount={totalItemCount}
                updateQueryLimit={(start, limit) =>
                  handleSetPagination(start, limit)
                }
              />
            </NSHeatmapStyle.PaginationContainer>
          )}
        </>
      ) : (
        <NSHeatmapStyle.NotFoundText>
          {i18n.t("general.noRecord")}
        </NSHeatmapStyle.NotFoundText>
      )}
    </NSHeatmapStyle.TableListContainer>
  );
};

export default Heatmap;
