import { Trans } from "react-i18next";
import LinkButton from "../../../../components/LinkButton/LinkButton";
import OutlinedButton from "../../../../components/OutlinedButton/OutlinedButton";
import SolidButton from "../../../../components/SolidButton/SolidButton";
import i18n from "../../../../library/i18next";
import colors from "../../../../style/colors";
import InfoBadge from "../ScoreResultInfoBadge/ScoreResultInfoBadge";
import { NSScoreResultSidebarType } from "./ScoreResultSideBar.type";
import { NSCScoreResultSidebarStyle } from "./ScoreResultSidebar.style";

const ScoreResultSidebar = ({
  data,
}: NSScoreResultSidebarType.IScoreResultSidebarData) => {
  return data.map((i) => (
    <NSCScoreResultSidebarStyle.Sidebar key={i.fullName}>
      <NSCScoreResultSidebarStyle.FullName>
        {i.fullName}
      </NSCScoreResultSidebarStyle.FullName>
      <NSCScoreResultSidebarStyle.PersonalInfo>
        <NSCScoreResultSidebarStyle.Strong>
          {i18n.t("candidate.email")}:
        </NSCScoreResultSidebarStyle.Strong>{" "}
        {i.email}
      </NSCScoreResultSidebarStyle.PersonalInfo>
      <NSCScoreResultSidebarStyle.PersonalInfo>
        <NSCScoreResultSidebarStyle.Strong>
          {i18n.t("candidate.phone")}:
        </NSCScoreResultSidebarStyle.Strong>{" "}
        {i.phone}
      </NSCScoreResultSidebarStyle.PersonalInfo>
      <NSCScoreResultSidebarStyle.ViewCvContainer>
        <LinkButton
          label={i18n.t("projects.downloadCv")}
          variant="label-w-icon-prefix"
          prefixIcon="File"
          onClick={i.onClickViewCv}
          disabled={i.isCvButtonDisabled}
        />
      </NSCScoreResultSidebarStyle.ViewCvContainer>
      {data.length <= 1 ? (
        <>
          <NSCScoreResultSidebarStyle.Divider />
          <NSCScoreResultSidebarStyle.PersonalInfo>
            <NSCScoreResultSidebarStyle.Strong>
              {i18n.t("candidate.company")}:
            </NSCScoreResultSidebarStyle.Strong>{" "}
            {i.companyName}
          </NSCScoreResultSidebarStyle.PersonalInfo>
          <NSCScoreResultSidebarStyle.PersonalInfo>
            <NSCScoreResultSidebarStyle.Strong>
              {i18n.t("candidate.project")}:
            </NSCScoreResultSidebarStyle.Strong>{" "}
            {i.projectName}
          </NSCScoreResultSidebarStyle.PersonalInfo>
          <NSCScoreResultSidebarStyle.SidebarButtonsContainer>
            <SolidButton
              label={i18n.t("candidate.watchVideo")}
              variant="label-w-icon-prefix"
              prefixIcon="Video"
              iconColor={colors.light.light}
              onClick={i.onClickWatchVideo}
              fullWidth
            />
            {i.interviewType === "ENGLISH_ASSESSMENT" ? null : (
              <OutlinedButton
                label={i18n.t("candidate.rescore")}
                variant="label-w-icon-prefix"
                prefixIcon="ArrowRestart"
                iconColor={colors.primary.primary}
                fullWidth
                onClick={i.onClickRescore}
              />
            )}
          </NSCScoreResultSidebarStyle.SidebarButtonsContainer>
        </>
      ) : (
        <NSCScoreResultSidebarStyle.ViewCvContainer>
          <LinkButton
            label={i18n.t("projects.viewVideo")}
            variant="label-w-icon-prefix"
            prefixIcon="Video"
            onClick={i.onClickWatchVideo}
            iconColor={colors.primary.primary}
          />
        </NSCScoreResultSidebarStyle.ViewCvContainer>
      )}
      <NSCScoreResultSidebarStyle.BadgeContainer>
        {
          <InfoBadge
            title={
              <Trans
                i18nKey="candidate.interviewDateRateText"
                values={{
                  dateRate: i.interviewDateRate,
                }}
              />
            }
            label={
              <Trans
                i18nKey="candidate.interviewCompletedAtText"
                values={{
                  interviewDate: i.interviewDate,
                }}
              />
            }
            variant="blue"
          />
        }
        {/*  <InfoBadge
          title={i18n.t("candidate.technicalReview")}
          label={
            "İnternet bağlatısında sorun yaşamadı. Kamera açık bir şekilde katılım sağladı."
          }
          variant="green"
        /> */}
        <InfoBadge
          title={i18n.t("candidate.interviewReview")}
          label={
            <Trans
              i18nKey="candidate.interviewReviewText"
              values={{
                interviewDate: i.interviewDate,
                reviewedBy: i.reviewedBy,
              }}
            />
          }
          variant="brown"
        />
      </NSCScoreResultSidebarStyle.BadgeContainer>
    </NSCScoreResultSidebarStyle.Sidebar>
  ));
};

export default ScoreResultSidebar;
