import styled from "styled-components";
import { NSDrawer } from "../../../../components/Drawer/Drawer.style";
import { NSCheckboxStyle } from "../../../../components/Checkbox/CheckBox.style";

export namespace NSCScoreResultDrawerStyle {
  export const DrawerSubtitle = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.neutrals.grey07};

    margin-top: 4px;
    margin-bottom: 24px;
  `;

  export const DrawerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  `;

  export const DrawerContent = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const Container = styled.div`
    ${NSDrawer.MenuContainer} {
      width: 40%;
      justify-content: space-between;
    }
  `;

  export const Candidate = styled.div`
    display: flex;
    flex-direction: column;
  `;

  export const CandidateProgressBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `;

  export const Name = styled.div`
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const ProgressBarContainer = styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    min-width: 250px;

    ${NSCheckboxStyle.CheckboxContainer} {
      border-radius: 100%;
      width: 32px;
      height: 32px;

      svg {
        path {
          stroke: ${(props) => props.theme.colors.light.light};
        }
      }
    }

    ${NSCheckboxStyle.StyledCheckbox} {
      width: 32px;
      height: 32px;
      border-radius: 100%;
    }
  `;

  export const ProgressBar = styled.div`
    width: 100%;
    height: 10px;
    display: flex;
    align-items: center;
    background-color: #e0e0e0;
    border-radius: 8px;
    position: relative;
  `;

  export const ScoreThumb = styled.div<{
    width: number;
    backgroundColor: string;
  }>`
    width: ${(props) => props.width}%;
    height: 100%;
    position: absolute;
    border-radius: 8px;
    background-color: ${(props) => props.backgroundColor};
    animation: progress 800ms ease-in;

    @keyframes progress {
      from {
        width: 0;
        background-color: transparent;
      }
    }
  `;

  export const ProgressText = styled.div`
    display: flex;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const Bold = styled.div`
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
  `;

  export const Inputs = styled.div`
    margin: 24px 0;
    display: grid;
    grid-template-columns: 2fr 3fr;
    gap: 8px;
  `;

  export const CandidatesContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
  `;
}
