import colors from "../style/colors";

export const getBadgeColor = (score: number) => {
  const ranges = [
    {
      min: 10,
      max: 24,
      color: colors.error.red03,
      backgroundColor: colors.error.red02,
    },
    {
      min: 25,
      max: 49,
      color: "hsla(39, 86%, 26%, 1)",
      backgroundColor: "hsla(44, 76%, 84%, 1)",
    },
    {
      min: 50,
      max: 69,
      color: "hsla(54, 83%, 26%, 1)",
      backgroundColor: "hsla(54, 70%, 86%, 1)",
    },
    {
      min: 70,
      max: 84,
      color: colors.information.blue03,
      backgroundColor: colors.information.blue02,
    },
    {
      min: 85,
      max: 100,
      color: colors.success.green03,
      backgroundColor: colors.success.green02,
    },
  ];

  const matchedRange = ranges.find(
    (range) => score >= range.min && score <= range.max
  );

  return (
    matchedRange ?? {
      color: colors.error.red03,
      backgroundColor: colors.error.red02,
    }
  );
};
