import Icon from "../../../../components/Icon/Icon";
import i18n from "../../../../library/i18next";
import { NSCandidateScoreResultStyle } from "../Candidate-Score-Result.style";
import useCandidateScoreResultVm from "../Candidate-Score-ResultVM";
import { NSCScoreResultDetailsStyle } from "./ScoreResultDetails.style";

const ScoreResultDetails = () => {
  const { scoreResultDetailData } = useCandidateScoreResultVm();
  return (
    <NSCScoreResultDetailsStyle.Container>
      {scoreResultDetailData.data.map((i) => (
        <NSCScoreResultDetailsStyle.ScoreResultDetail
          key={i.candidate}
          isBackgroundActive={scoreResultDetailData.data.length <= 1}
        >
          {scoreResultDetailData.data.length > 1 && (
            <NSCScoreResultDetailsStyle.ScoreResultDetailItem>
              {i.candidate}
            </NSCScoreResultDetailsStyle.ScoreResultDetailItem>
          )}
          {i.items.map((item) => (
            <NSCScoreResultDetailsStyle.ScoreResultDetailItem
              key={item.reviewMetric}
            >
              <NSCScoreResultDetailsStyle.ScoreResultDetailReviewMetric>
                {item.isHaveWeight ? (
                  <>
                    <Icon name="AI" />
                    {item.reviewMetric}
                  </>
                ) : (
                  <>
                    <NSCandidateScoreResultStyle.InfoTextBonusQuestion>
                      {i18n.t("candidate.freeCommentArea")}{" "}
                    </NSCandidateScoreResultStyle.InfoTextBonusQuestion>
                    <NSCandidateScoreResultStyle.ExplanationInfoIcon>
                      <Icon name="WarningCircleInfo" />
                    </NSCandidateScoreResultStyle.ExplanationInfoIcon>
                    <NSCandidateScoreResultStyle.ExplanationInfo>
                      {i18n.t("candidate.infoBonusQuestion")}
                    </NSCandidateScoreResultStyle.ExplanationInfo>
                  </>
                )}{" "}
              </NSCScoreResultDetailsStyle.ScoreResultDetailReviewMetric>
              <NSCScoreResultDetailsStyle.ScoreResultDetailReason>
                <NSCScoreResultDetailsStyle.ScoreResultDetailReasonText>
                  {item.reason
                    ? item.reason
                    : i18n.t("candidate.noDescription")}
                </NSCScoreResultDetailsStyle.ScoreResultDetailReasonText>
              </NSCScoreResultDetailsStyle.ScoreResultDetailReason>
            </NSCScoreResultDetailsStyle.ScoreResultDetailItem>
          ))}
        </NSCScoreResultDetailsStyle.ScoreResultDetail>
      ))}
    </NSCScoreResultDetailsStyle.Container>
  );
};

export default ScoreResultDetails;
