import styled, { css } from "styled-components";
import { NSMainButtonStyle } from "../../../components/MainButton/MainButton.style";
import { NSCandidateResultInfoCardStyle } from "../../../components/CandidateResultInfoCard/CandidateResultInfoCard.style";

export namespace NSCandidateScoreResultStyle {
  export const Container = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: scroll;
    background-color: ${(props) => props.theme.colors.neutrals.grey02};
  `;

  export const OverallScoreContainer = styled.div`
    height: 200px;
    width: 100%;
    ${NSCandidateResultInfoCardStyle.Header} {
      font-size: ${(props) => props.theme.typography.pharagraph1.size};
      line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
      font-weight: ${(props) => props.theme.typography.fontWeights.bold};
      padding: 16px;
    }
  `;

  export const ExportCandidateScoresContainer = styled.div`
    ${Container} {
      overflow: hidden;
    }

    ${OverallScoreContainer} {
      margin-top: 0;
    }
  `;

  export const DownloadButtonContainer = styled.div`
    padding: 0 20% 0;
    gap: 24px;
    display: flex;
    flex-direction: column;
    padding: 40px 32px;
  `;

  export const Header = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    width: max-content;
    gap: 12px;
    padding: 24px 32px;
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
  `;

  export const Content = styled.div`
    min-width: 1370px;
    max-width: 2450px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 16px;
    padding: 32px 32px 24px;
  `;

  export const LeftContent = styled.div``;
  export const RightContent = styled.div``;

  export const Navbar = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-radius: 16px;
    margin-bottom: 16px;
    background-color: ${(props) => props.theme.colors.light.light};
  `;

  export const NavbarButtonsContainer = styled.div<{ isCompared: boolean }>`
    display: flex;
    gap: 16px;
    align-items: center;

    ${(props) =>
      props.isCompared &&
      css`
        :first-child {
          ${NSMainButtonStyle.ButtonBase} {
            background-color: ${(props) => props.theme.colors.error.red01};
          }
        }
      `}

    ${NSMainButtonStyle.ButtonBase} {
      height: 40px;
    }

    & > *:not(:first-child) {
      ${NSMainButtonStyle.ButtonLabel} {
        color: ${(props) => props.theme.colors.secondary.secondary};
      }
    }
  `;

  export const InfoBonusQuestionContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: ${(props) => props.theme.typography.pharagraph1.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    color: ${(props) => props.theme.colors.dark.dark};
  `;

  export const InfoBonusQuestion = styled.span`
    font-size: ${(props) => props.theme.typography.preText.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.dark.dark};
    width: 324px;
    padding-left: 10px;
  `;

  export const InfoTextBonusQuestion = styled.div`
    display: flex;
    gap: 10px;
    font-size: ${(props) => props.theme.typography.pharagraph2.size};
    line-height: ${(props) => props.theme.typography.titleh4.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
    color: ${(props) => props.theme.colors.light.light};
    border-radius: 10px;
    background-color: rgb(90, 60, 150);
    padding: 10px;
    justify-content: center;
    width: 345px;
  `;

  export const ExplanationInfo = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.dark.dark};
  `;
  export const ExplanationInfoIcon = styled.div`
    font-size: ${(props) => props.theme.typography.smallText2.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    color: ${(props) => props.theme.colors.dark.dark};
    margin-left: 10px;
    svg {
      min-width: 15px;
      min-height: 15px;
      margin-bottom: -2px;
    }
  `;
  export const DescriptionsContainer = styled.div``;
  export const DescriptionsHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.pharagraph2.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.demiBold};
    margin: 8px 6px;
  `;
  export const DescriptionsSeperator = styled.br``;
  export const DescriptionsHeader = styled.h5`
    width: 100%;
  `;
}
