import styled from "styled-components";
import { NSScoreResultInfoBadgeType } from "./ScoreResultInfoBadge.type";

export namespace NSScoreResultInfoBadgeStyle {
  export const InfoBadgeContainer = styled.div<{
    variant?: NSScoreResultInfoBadgeType.ScoreResultInfoBadgeVariant;
  }>`
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: 16px;

    background-color: ${(props) => {
      switch (props.variant) {
        case "green":
          return props.theme.colors.infoBadgeGreen.background;
        case "blue":
          return props.theme.colors.infoBadgeBlue.background;
        case "brown":
          return props.theme.colors.infoBadgeBrown.background;
        default:
          return props.theme.colors.neutrals.grey07;
      }
    }};
    color: ${(props) => {
      switch (props.variant) {
        case "green":
          return props.theme.colors.infoBadgeGreen.color;
        case "blue":
          return props.theme.colors.infoBadgeBlue.color;
        case "brown":
          return props.theme.colors.infoBadgeBrown.color;
        default:
          return props.theme.colors.dark.dark;
      }
    }};
  `;

  export const InfoBadgeTitle = styled.div`
    font-size: ${(props) => props.theme.typography.smallText1.size};
    line-height: ${(props) => props.theme.typography.smallText1.lineHeight};
    font-weight: ${(props) => props.theme.typography.fontWeights.bold};
  `;

  export const InfoBadgeLabel = styled(InfoBadgeTitle)`
    font-weight: ${(props) => props.theme.typography.fontWeights.regular};
    margin-top: 8px;
  `;
}
