import { ButtonHTMLAttributes, MouseEventHandler, ReactNode } from "react";

import icons from "../../assets/icons";

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  isLoading?: boolean;
  size?: ButtonSizeVariant;
  label?: string | ReactNode;
  fullWidth?: boolean;
  variant?: ButtonTypeVariant;
  isIconButton?: boolean;
  iconColor?: string;
  suffixIcon?: keyof typeof icons;
  prefixIcon?: keyof typeof icons;
  loadingContent?: ReactNode;
}

export const ButtonHeightBySizeTypes = {
  large: "56px",
  medium: "48px",
  small: "32px",
};

export const ButtonPaddingBySizeTypes = {
  large: "16px",
  medium: "12px",
  small: "8px",
};

export type ButtonSizeVariant = "large" | "medium" | "small";

export enum ButtonColorVariant {
  primary = "hsla(234, 70%, 50%, 1)",
  grey = "hsla(214, 33%, 17%, 1)",
  teriary = "hsla(133, 64%, 64%, 1)",
  secondary = "hsla(240, 14%, 89%, 1)",
  error = "hsla(349, 73%, 56%, 1)",
}

export type ButtonTypeVariant =
  | "basic"
  | "label-w-icon-prefix"
  | "label-w-icon-suffix"
  | "label-w-icon-prefix-&-suffix";
