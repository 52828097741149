import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { jsPDF } from "jspdf";
import domtoimage from "dom-to-image";
import i18n from "../../../library/i18next";
import { NSScoreResultSidebarType } from "./ScoreResultSidebar/ScoreResultSideBar.type";
import capitalizeFirstLetterHelper from "../../../utils/capitalizeFirstLetterHelper";
import dayjs from "dayjs";
import { useGraphQLMutation, useGraphQLQuery } from "../../../hooks/useGraphQL";
import {
  GetAllVideoInterviewByProjectIdDocument,
  GetSignedUrlDocument,
  GetVideoInterviewByIdsDocument,
  Video_Interview_Type_Enum,
} from "../../../gql/graphql";
import { NSGeneralScoreType } from "./ScoreResultOverview/GeneralScore/GeneralScore.type";
import { NSScoreDistributionType } from "./ScoreResultOverview/ScoreDistribution/ScoreDistribution.type";
import fileTypeHelper from "../../../utils/fileTypeHelper";
import { CV, GET } from "../../../utils/globalConstants";
import { NSCandidateListType } from "../../Project/Detail/Candidate-List/Candidate-List.type";
import { NSScoreResultDetailsType } from "./ScoreResultDetails/ScoreResultDetails.type";
import { NSCandidateScoreResultStyle } from "./Candidate-Score-Result.style";
import { getLevel } from "../../../utils/getLevelHelper";
import Icon from "../../../components/Icon/Icon";

interface ChartConfig {
  [key: string]: { label: string };
}

const useCandidateScoreResultVm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { secondInterviewId, hasCameFromHeatmap } = location.state || {};

  const linkToTab: { [key: string]: number } = useMemo(
    () => ({
      overview: 1,
      details: 2,
    }),
    []
  );

  const getActiveTab = () => {
    const urlArray = window.location.href.split("/");
    const activeUrl = urlArray[urlArray.length - 1];
    return linkToTab[activeUrl] ?? 1;
  };
  const [activeTab, setActiveTab] = useState(getActiveTab());
  const { projectId, interviewId, baseType } = useParams();
  const { data: allVideoInterviews } = useGraphQLQuery(
    GetAllVideoInterviewByProjectIdDocument,
    {},
    {
      interviewType:
        baseType === NSCandidateListType.ScorePageBaseType.INTERVIEW
          ? Video_Interview_Type_Enum.EnglishAssessment
          : Video_Interview_Type_Enum.CompetencyAssessment,
      projectId,
    }
  );
  const { data: videoInterviewData, isLoading } = useGraphQLQuery(
    GetVideoInterviewByIdsDocument,
    {},
    {
      ids: secondInterviewId ? [interviewId, secondInterviewId] : [interviewId],
    }
  );

  const { mutateAsync: getSignedUrl } =
    useGraphQLMutation(GetSignedUrlDocument);

  const handleDownload = useCallback(async (url: string, name: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const linkkk = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = linkkk;
      a.download = name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(linkkk);
    } catch (error) {
      console.log("Download failed:", error);
    }
  }, []);

  const closeHandler = () => {
    navigate(
      `/projects/detail/${projectId}/${
        hasCameFromHeatmap ? "heatmap" : "long-list"
      }`
    );
  };

  useEffect(() => {
    const handlePopState = () => {
      closeHandler();
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [projectId, hasCameFromHeatmap]);

  const reportSidebarData: NSScoreResultSidebarType.IScoreResultSidebar[] =
    videoInterviewData?.video_interview.map((v) => ({
      fullName: capitalizeFirstLetterHelper(
        `${v?.client_candidate_project.client_candidate.name} ${v?.client_candidate_project.client_candidate.surname}`
      ),
      interviewType: v.video_interview_type,
      email: v?.client_candidate_project.client_candidate?.email_address ?? "",
      phone: v?.client_candidate_project.client_candidate.mobile_number ?? "",
      companyName:
        v?.client_candidate_project.client_candidate.client.company
          .company_name ?? "",
      projectName: v?.client_candidate_project.project.project_name ?? "",
      interviewDateRate:
        v?.client_candidate_project.project.interview_starts_at &&
        v?.client_candidate_project.project.interview_ends_at
          ? `${dayjs(
              v?.client_candidate_project.project.interview_starts_at
            ).format("DD.MM.YYYY[,] hh:mma")} - ${dayjs(
              v?.client_candidate_project.project.interview_ends_at
            ).format("DD.MM.YYYY[,] hh:mma")}`
          : null,
      interviewDate: v?.completed_at
        ? dayjs(v?.completed_at).format("DD.MM.YYYY[,] hh:mma")
        : null,
      reviewedBy: v?.video_interview_questions
        .find((i) => i.video_interview_score.length > 1)
        ?.video_interview_score.find((item) => item.is_ai_score === false)
        ?.video_interview_scored_by?.name
        ? `AI + ${
            v?.video_interview_questions
              .find((i) => i.video_interview_score.length > 1)
              ?.video_interview_score.find((item) => item.is_ai_score === false)
              ?.video_interview_scored_by?.name
          }`
        : `AI`,
      isCvButtonDisabled:
        !v.client_candidate_project.client_candidate.cv_file_name,
      onClickRescore: () => {
        navigate(
          `/projects/detail/${projectId}/candidate/candidate-reviewer-questions/${v.id}`,
          {
            state: { projectId, interviewId: v.id },
          }
        );
      },
      onClickViewCv: async () => {
        let fileUrlData: any;
        const fileName =
          v?.client_candidate_project?.client_candidate?.cv_file_name ?? "";
        const type = fileTypeHelper(fileName);
        fileUrlData = await getSignedUrl({
          signedUrlInput: {
            contentType: type.type!,
            filenames: [fileName],
            folder: CV,
            operation: GET,
          },
        });

        handleDownload(
          fileUrlData?.getSignedUrl?.signedUrls[0] as string,
          fileName
        );
      },
      onClickWatchVideo: () => {
        navigate(
          `/projects/detail/${projectId}/candidate/candidate-video-preview/${v.id}`,
          {
            state: { projectId, interviewId: v.id },
          }
        );
      },
    })) as NSScoreResultSidebarType.IScoreResultSidebar[];

  const generalScoreData: NSGeneralScoreType.IGeneralScore[] =
    videoInterviewData?.video_interview.map((v) => ({
      id: "1",
      score: v?.score ?? 0,
      candidate:
        capitalizeFirstLetterHelper(
          `${v?.client_candidate_project.client_candidate.name}`
        ) ?? "",
      order:
        (allVideoInterviews?.video_interview.findIndex((i) => i.id === v.id) ??
          0) + 1,
      stringProgressValue:
        baseType === NSCandidateListType.ScorePageBaseType.INTERVIEW
          ? getLevel(v?.score ?? 0)
          : undefined,
    })) as NSGeneralScoreType.IGeneralScore[];

  const scoreDistributionData: NSScoreDistributionType.IScoreDistributionItem[] =
    baseType === NSCandidateListType.ScorePageBaseType.INTERVIEW
      ? (videoInterviewData?.video_interview_criterion_score ?? []).reduce(
          (acc, item) => {
            const reviewMetric = item.interview_criterion.name;
            const score = item.score;
            const existingMetric = acc.find(
              (metric) => metric.reviewMetric === reviewMetric
            );
            if (existingMetric) {
              existingMetric.scores.push(score);
            } else {
              acc.push({
                reviewMetric,
                scores: [score],
              });
            }

            return acc;
          },
          [] as NSScoreDistributionType.IScoreDistributionItem[]
        )
      : (videoInterviewData?.video_interview[0]?.video_interview_questions
          ?.map((question) => {
            const isHaveWeight = videoInterviewData.video_interview.some(
              (interview) =>
                interview?.client_candidate_project.project.interview_template?.interview_template_review_metric_questions.some(
                  (x) =>
                    x.review_metric_question.id ===
                      question.review_metric_question.id &&
                    typeof x.weight === "number" &&
                    x.weight > 0
                )
            );

            if (!isHaveWeight) return null;

            const scores = videoInterviewData.video_interview.map(
              (interview) => {
                const matchingQuestion =
                  interview.video_interview_questions.find(
                    (q) =>
                      q.review_metric_question.id ===
                      question.review_metric_question.id
                  );

                const userScore = matchingQuestion?.video_interview_score.find(
                  (i) => !i.is_ai_score
                )?.review_metric_question_response_id;

                const aiScore = matchingQuestion?.video_interview_score.find(
                  (i) => i.is_ai_score
                )?.review_metric_question_response_id;

                const userScoreValue =
                  matchingQuestion?.review_metric_question.review_metric_question_responses?.find(
                    (i) => i.id === userScore
                  )?.score;

                const aiScoreValue =
                  matchingQuestion?.review_metric_question.review_metric_question_responses?.find(
                    (i) => i.id === aiScore
                  )?.score;

                return userScoreValue ?? aiScoreValue ?? 0;
              }
            );

            return {
              reviewMetric:
                question.review_metric_question.review_metric.description,
              scores,
            };
          })
          ?.filter(
            (item) => item !== null
          ) as NSScoreDistributionType.IScoreDistributionItem[]) || [];

  const chartData =
    scoreDistributionData?.map((i) => {
      return {
        item: i.reviewMetric,
        candidate1: i.scores[0] ?? null,
        candidate2: i.scores[1] ?? null,
        /*   average: 64, */
      };
    }) ?? [];

  const candidates = videoInterviewData?.video_interview.map((i) =>
    capitalizeFirstLetterHelper(
      i.client_candidate_project.client_candidate.name
    )
  );

  const englishLevels =
    baseType === NSCandidateListType.ScorePageBaseType.INTERVIEW
      ? videoInterviewData?.video_interview.map((i) => getLevel(i.score))
      : videoInterviewData?.video_interview.flatMap((a) => {
          const maxScore = a.client_candidate_project.video_interviews
            .filter((x) => (x.score ?? 0) > 0)
            .reduce((max, item) => (item.score! > max ? item.score! : max), 0);

          return maxScore > 0 ? getLevel(maxScore) : null;
        });

  const chartConfig = videoInterviewData?.video_interview.reduce<ChartConfig>(
    (acc, interview, index) => {
      const candidateKey = `candidate${index + 1}`;
      acc[candidateKey] = {
        label: capitalizeFirstLetterHelper(
          interview.client_candidate_project.client_candidate.name
        ),
      };

      return acc;
    },
    {
      /* average: {
        label: i18n.t("candidate.average"),
      }, */
    }
  );

  const downloadButton = () => {
    const content = document.getElementById("sub-content") as HTMLElement;

    domtoimage
      .toPng(content, { quality: 0.8 })
      .then(function (dataUrl: string) {
        const img = new Image();
        img.src = dataUrl;

        img.onload = () => {
          const pdf = new jsPDF("portrait", "pt", "a4");
          const imgWidth = img.width;
          const imgHeight = img.height;

          const pdfWidth = pdf.internal.pageSize.getWidth();
          const scaleFactor = pdfWidth / imgWidth;
          const scaledHeight = imgHeight * scaleFactor;

          pdf.internal.pageSize.height = scaledHeight;

          pdf.addImage(
            dataUrl,
            "PNG",
            0,
            0,
            pdfWidth,
            scaledHeight,
            undefined,
            "FAST"
          );

          const pdfName = `${
            videoInterviewData?.video_interview[0]?.client_candidate_project
              .client_candidate?.name ?? ""
          }${
            videoInterviewData?.video_interview[0]?.client_candidate_project
              .client_candidate?.surname ?? ""
          }`.trim();

          pdf.save(`${pdfName}.pdf`);
        };
      })
      .catch(function (error: string) {
        console.error("PDF Oluşturulamadı!", error);
      });
  };

  type urlLinksType = {
    [key: number]: string;
  };

  const urlLinks: urlLinksType = useMemo(
    () => ({
      1: "overview",
      2: "details",
    }),
    []
  );

  const reverseUrlLinks = useMemo(() => {
    const reverseMap: Record<string, number> = {};
    for (const [key, value] of Object.entries(urlLinks)) {
      reverseMap[value] = parseInt(key, 10);
    }
    return reverseMap;
  }, [urlLinks]);

  const onTabChange = (tab: number) => {
    setActiveTab(tab);
    navigate(`${urlLinks[tab]}`, {
      state: {
        projectId,
        interviewId,
        secondInterviewId,
        hasCameFromHeatmap,
      },
    });
  };

  useEffect(() => {
    const path = location.pathname.split("/").pop();
    const matchedTab = reverseUrlLinks[path ?? ""];
    if (matchedTab && matchedTab !== activeTab) {
      setActiveTab(matchedTab);
    }
  }, [location.pathname, reverseUrlLinks, activeTab]);

  const tabItems = [
    {
      id: 1,
      label: i18n.t("projects.overview"),
      path: "overview",
    },
    {
      id: 2,
      label: i18n.t("projects.details"),
      path: "details",
    },
  ];

  const isDrawerVisible = location.search.includes("drawer=open");

  const handleCompareClick = () => {
    if (secondInterviewId) {
      navigate(location.pathname, {
        state: {
          secondInterviewId: null,
        },
      });
      return;
    }
    navigate(`${location.pathname}?drawer=open`, {
      state: {
        hasCameFromHeatmap,
      },
    });
  };

  const closeDrawer = () => {
    navigate(-1);
  };

  const createCandidateItem = (
    name: string,
    surname: string,
    items: NSScoreResultDetailsType.IScoreResultDetailItem[]
  ): NSScoreResultDetailsType.IScoreResultDetail => ({
    candidate: capitalizeFirstLetterHelper(
      `${name || ""} ${surname || ""}`.trim()
    ),
    items,
  });

  const scoreResultDetailData: NSScoreResultDetailsType.IScoreResultDetailData =
    {
      data:
        baseType === NSCandidateListType.ScorePageBaseType.INTERVIEW
          ? Object.values(
              (
                videoInterviewData?.video_interview_criterion_score ?? []
              ).reduce((acc, item) => {
                const interviewId = item.video_interview.id;

                if (!acc[interviewId]) {
                  acc[interviewId] = createCandidateItem(
                    item.video_interview.client_candidate_project
                      .client_candidate.name,
                    item.video_interview.client_candidate_project
                      .client_candidate.surname,
                    []
                  );
                }

                acc[interviewId].items.push({
                  reviewMetric: item.interview_criterion.name,
                  reason: item.score_description ?? null,
                  isAiScored: true,
                  isHaveWeight: !!videoInterviewData?.video_interview.some(
                    (s) =>
                      s.client_candidate_project.project.interview_template?.interview_template_review_metric_questions.some(
                        (x) => typeof x.weight === "number" && x.weight > 0
                      )
                  ),
                });
                return acc;
              }, {} as Record<string, NSScoreResultDetailsType.IScoreResultDetail>)
            )
          : (videoInterviewData?.video_interview ?? []).map((interview) =>
              createCandidateItem(
                interview.client_candidate_project.client_candidate.name,
                interview.client_candidate_project.client_candidate.surname,
                (interview.video_interview_questions ?? []).map((item) => {
                  const nonAiDesc = item.video_interview_score.find(
                    (i) => !i.is_ai_score
                  )?.score_description;
                  const aiDesc = item.video_interview_score.find(
                    (i) => i.is_ai_score
                  )?.score_description;
                  const combinedReason =
                    nonAiDesc && aiDesc ? (
                      <NSCandidateScoreResultStyle.DescriptionsContainer>
                        {aiDesc}
                        <NSCandidateScoreResultStyle.DescriptionsSeperator />
                        <NSCandidateScoreResultStyle.DescriptionsHeaderContainer>
                          <Icon name="RescoreIcon" />
                          <NSCandidateScoreResultStyle.DescriptionsHeader>
                            {i18n.t("projects.rescoreHeader")}
                          </NSCandidateScoreResultStyle.DescriptionsHeader>
                        </NSCandidateScoreResultStyle.DescriptionsHeaderContainer>
                        {nonAiDesc}
                      </NSCandidateScoreResultStyle.DescriptionsContainer>
                    ) : (
                      nonAiDesc ?? aiDesc ?? ""
                    );

                  return {
                    reviewMetric:
                      item.review_metric_question.review_metric.description,
                    reason: combinedReason,
                    isAiScored: !item.video_interview_score.some(
                      (i) => !i.is_ai_score
                    ),
                    isHaveWeight:
                      !!interview?.client_candidate_project.project.interview_template?.interview_template_review_metric_questions.find(
                        (x) =>
                          x.review_metric_question.id ===
                            item.review_metric_question.id &&
                          typeof x.weight === "number" &&
                          x.weight > 0
                      ),
                  };
                })
              )
            ),
    };

  return {
    baseType,
    reportSidebarData,
    isDrawerVisible,
    isLoading: isLoading,
    activeTab,
    tabItems,
    generalScoreData,
    scoreDistributionData,
    chartData,
    chartConfig,
    secondInterviewId,
    scoreResultDetailData,
    candidates,
    englishLevels,
    closeHandler,
    onTabChange,
    downloadButton,
    handleCompareClick,
    closeDrawer,
  };
};

export default useCandidateScoreResultVm;
